import {ENV} from "./env";

export const API_URL = ENV.API_URI;

export const CDN_URL = ENV.CDN_URL;

export const SOCKET_ENDPOINT = ENV.SOCKET_ENDPOINT

export const SMALL_MOBILE_BREAKPOINT_WIDTH = 480;

export const BREAKPOINT_WIDTH = 1024;

export const TEST_FAILED_CARD = "4012000033330026";
export const TEST_AUTH_CARD = "4242424242424242";

export const TABLE_BREAKPOINT_WIDTH = 1366;

export const LARGE_BREAKPOINT_WIDTH = 1600;

export const APP_DEV = ENV.APP_IS_DEV

export const LOGIN_PATH = "/login"

export const IBAN_REGEX = /QA[0-9]{2} ?([A-Za-z]{4}) ?[\d]{4} ?[\d]{4} ?[\d]{4} ?[\d]{4} ?[\d]{4} ?[\d]/;
export const URL_REGEX = /((https?:\/\/)?(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
export const IBAN_REGEX_INPUT_MASK = [
    /[a-zA-Z]/,
    /[a-zA-Z]/,
    /\d/,
    /\d/,

    " ",

    /[a-zA-Z]/,
    /[a-zA-Z]/,
    /[a-zA-Z]/,
    /[a-zA-Z]/,

    " ",

    /\d/,
    /\d/,
    /\d/,
    /\d/,

    " ",

    /\d/,
    /\d/,
    /\d/,
    /\d/,

    " ",

    /\d/,
    /\d/,
    /\d/,
    /\d/,

    " ",

    /\d/,
    /\d/,
    /\d/,
    /\d/,

    " ",

    /\d/,
    /\d/,
    /\d/,
    /\d/,

    " ",
    /\d/
]


export const DOCUMENTS_TYPE = ".png,.jpg,.pdf,"
export const IMAGES_TYPE = ".png,.jpg"

export const PROTOCOL = window.location.protocol
export const LOCAL_IP = "localhost"
export const IS_LOCAL = String(window.location.host).startsWith("localhost")

export const SASS_COLORS = {
    GRAY: "#6C7985",
    PRIMARY: "#33CC66",
    SECONDARY: "#0C2036",
    LIGHT_GRAY: "#D0D1D1"
}

export const TEST_MODE_PREFIX = "test"

export const MAX_DOCUMENT_SIZE = 10
export const MAX_LOGO_SIZE = 2

export const CHARGE_METHOD = {
    CREDIT_CARD: "creditcard",
    DEBIT_CARD: "naps",
    AMEX: "amex",
    APPEL_PAY: "applepay",
    GOOGLE_PAY: "googlepay",
}