// page paths
export const Routes = {
  login: "/login",
  register: "/signup",
  registerInvite: "/memberships/accept/invite",
  forgotPasswd: "/forgot-password",
  registerVerify: "/verify",
  resetPasswd: "/reset-password",
  resetVerify: "/reset-verify",
  mfaVerify: "/mfa-verify",
  Order: "/link-details",
  dashboard: "/",
  OBHome: "/onboarding",
  OBCompany: "/onboarding/company",
  OBStackholders: "/onboarding/stackholders",
  OBProfile: "/onboarding/profile",
  OBPayout: "/onboarding/payout",
  OBDocuments: "/onboarding/documents",
  PYHome: "/payments",
  PYOrderDetails: "/payments/:uid",
  LinksHome: "/links",
  onboardingDocumentsMobile: "/m/onboarding/documents",
  LinkCheckout: "/pay/:uid",
  HostedCheckoutLink: "/payscreen/:paymentToken",
  Balance: "/balance",
  Team: "/team",
  Customers: "/customers",

  Settings: {
    General: "/settings",
    Payout: "/settings/payout",
    Checkout: "/settings/checkout",
    Developer: "/settings/api-keys",
    Methods: "/settings/payment-methods",
    Security: "/settings/security",
  },
  CallBacks: {
    Base: "/callback",
    Verify: {
      EmailChanged: "/callback/verify/email_change/:token",
    },
  },
  Insights: {
    Base: "/insights",
  },
  Help: {
    Base: "https://support.dibsy.one",
  },
};
